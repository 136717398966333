/**
 * @flow
 */

import * as React from 'react';
import ExternalLink from 'src/components/navigation/ExternalLink';
import {visuallyHiddenStyle, linkStyle} from 'src/styles/common';
import {
  EMAIL_URL,
  TWITTER_PROFILE_URL,
  TIKTOK_PROFILE_URL,
  INSTAGRAM_PROFILE_URL,
  SPOTIFY_FEATURED_URL,
  APPLE_MUSIC_FEATURED_URL,
  YOUTUBE_FEATURED_URL,
  ETSY_SHOP_URL,
  FACEBOOK_PROFILE_URL,
} from 'src/constants';
import {
  // TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  SpotifyIcon,
  AppleMusicIcon,
  YoutubeIcon,
  // EtsyIcon,
  // EnvelopeIcon,
} from 'src/components/icons/Icons';

export type IconLinkInfo = $ReadOnly<{
  label: string,
  href: string,
  Icon: React.ComponentType<{}>,
}>;

// export const twitterIconLinkInfo: IconLinkInfo = {
//   label: 'Twitter',
//   href: TWITTER_PROFILE_URL,
//   Icon: TwitterIcon,
// };
export const instagramIconLinkInfo: IconLinkInfo = {
  label: 'Instagram',
  href: INSTAGRAM_PROFILE_URL,
  Icon: InstagramIcon,
};
export const facebookIconLinkInfo: IconLinkInfo = {
  label: 'Facebook',
  href: FACEBOOK_PROFILE_URL,
  Icon: FacebookIcon,
};
export const tiktokIconLinkInfo: IconLinkInfo = {
  label: 'Tiktok',
  href: TIKTOK_PROFILE_URL,
  Icon: TiktokIcon,
};
export const spotifyIconLinkInfo: IconLinkInfo = {
  label: 'Spotify',
  href: SPOTIFY_FEATURED_URL,
  Icon: SpotifyIcon,
};
export const appleMusicIconLinkInfo: IconLinkInfo = {
  label: 'Apple Music',
  href: APPLE_MUSIC_FEATURED_URL,
  Icon: AppleMusicIcon,
};
export const youtubeIconLinkInfo: IconLinkInfo = {
  label: 'Youtube',
  href: YOUTUBE_FEATURED_URL,
  Icon: YoutubeIcon,
};
// export const etsyIconLinkInfo: IconLinkInfo = {
//   label: 'Etsy',
//   href: ETSY_SHOP_URL,
//   Icon: EtsyIcon,
// };
// export const emailIconLinkInfo: IconLinkInfo = {
//   label: 'Email',
//   href: EMAIL_URL,
//   Icon: EnvelopeIcon,
// };

type Props = $ReadOnly<{
  showLabel?: ?boolean,
  showIcon?: ?boolean,
  iconLinkInfo: IconLinkInfo,
  color: string,
  hoverColor: string,
}>;

export const ICON_LINK_PADDING = 15;
const styles = {
  link: (theme) => ({
    ...linkStyle(theme),
    fontSize: `${theme.fontSizeIcons}rem`,
    padding: ICON_LINK_PADDING,
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
  }),
  label: (theme) => ({
    fontSize: `${theme.fontSizeMedium}rem`,
  }),
};

export default function IconLink(props: Props): React.Node {
  const {
    showLabel,
    showIcon,
    iconLinkInfo: {label, href, Icon},
    color,
    hoverColor,
  } = props;
  const finalShowLabel = showLabel ?? false;
  const finalShowIcon = showIcon ?? true;
  const linkStyle = (theme) => ({
    ...styles.link(theme),
    color,
    ':hover': {
      color: hoverColor,
    },
  });
  const labelStyle = finalShowLabel
    ? (theme) => ({...styles.label(theme), paddingLeft: finalShowIcon ? 8 : 0})
    : visuallyHiddenStyle;
  return (
    <ExternalLink href={href} css={linkStyle}>
      {finalShowIcon && <Icon />}
      <span css={labelStyle}>{label}</span>
    </ExternalLink>
  );
}
